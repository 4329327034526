function webopacCatalogueSerach(iframeID, wrapperClassName, contentTabID, pageRedirectSlug, iframeTarget) {

    var contentTabHTML = '';

    var iFrameDOMContent = $('iframe#' + iframeID).contents();
    var PanelSearchRow = iFrameDOMContent.find('.' + wrapperClassName + ' .PanelSearchRow');

    var webopacForm = iFrameDOMContent.find('form');
    var actionFormUrl = String($(webopacForm).attr('action'));

    if ((typeof $(PanelSearchRow).attr('class') == 'undefined') && ((typeof webopacForm !== "undefined") && (actionFormUrl.indexOf("register.aspx") > 0))) { // Register menu

        PanelSearchRow = iFrameDOMContent.find('.' + wrapperClassName + ' li span:not(.submenuseperator)');
        $(PanelSearchRow).each(function () {
            var regsiterWP = $(this).clone();
            var registerHref = $(regsiterWP).find('a');
            var registerWebopacURL = $(registerHref).attr('href');
            var registerURL = registerWebopacURL.split("?");
            $(registerHref).attr('href', '/' + pageRedirectSlug + '/?' + registerURL[1]);
            contentTabHTML = contentTabHTML + $(regsiterWP).html();
        });

        $('#' + contentTabID).html(contentTabHTML);

    } else { // form search 

        var idWPBtn = 'wpBtn-' + contentTabID;
        var submitBtn = iFrameDOMContent.find('input.searchBtn[type=submit]');
        var idSubmit = $(submitBtn).attr('id');

        if ($('#' + contentTabID).is(':empty')) {

            $(PanelSearchRow).each(function () {
                contentTabHTML = contentTabHTML + $(this).html();
            });
            $('#' + contentTabID).html(contentTabHTML);
            $('.toplist-buttons').remove();
            $(submitBtn).clone().appendTo('#' + contentTabID).attr('id', idWPBtn);

        }
        // on change - add the values to the webopac search
        var selectFields = iFrameDOMContent.find('select');
        $(selectFields).each(function () {
            $('#' + contentTabID + ' #' + $(this).attr('id')).change(function () {
                var selectC1 = iFrameDOMContent.find('#' + $(this).attr('id'));
                $(selectC1).val($(this).val());
            });
        });

        $('#' + idWPBtn).click(function () {
            var submitBtnIframe = iFrameDOMContent.find('#' + idSubmit);
            $(submitBtnIframe)[0].click();
        });

        $('select').selectpicker();
        if (iframeTarget == 'wpiframe') {
            // var iFrameDOMContentSearch = iFrameDOMContent.find('.webopac-wrapper .opaciframe');
            // var dataForm = iFrameDOMContentSearch.find('#aspnetForm').attr('action');
            // var dataForm = $(iFrameDOMContentSearch ).attr('src');
            var dataForm = iFrameDOMContent.find('#aspnetForm').attr('action');
            if (dataForm) {
                var dataQuery = dataForm.split('data=');
                if (typeof dataQuery[1] !== 'undefined') { // redirect to the WP page URL & pass the data encoded value
                    window.location.href = '/' + pageRedirectSlug + '/?data=' + dataQuery[1];
                }
            }
        }
    }

}

function webopacCatalogue(iframeID, wrapperClassName, contentTabID, pageRedirectSlug, iframeTarget) {

    var iframeLoading = false;
    $('.wrapper-loading-v2').show();
    if ($('#' + contentTabID).is(':empty')) {
        $('iframe#' + iframeID).bind('load', function () {

            var iFrameDOM = $('iframe#' + iframeID).contents();
            var webopacForm = iFrameDOM.find('form');
            var actionFormUrl = String($(webopacForm).attr('action'));

            if ((typeof webopacForm !== "undefined") && (typeof actionFormUrl !== "undefined")) {

                if (actionFormUrl.indexOf("detail.aspx") > 0) {
                    var iframeTargetSrc = $('#targetIframe').attr('src');
                    if (!iframeTargetSrc) {
                        $('#targetIframe').attr('src', WEBOPAC_DOMAIN + '/webopac/acquisitions.aspx?referer=MbaT87');
                    }
                    webopacCatalogueSerach('targetIframe', wrapperClassName, contentTabID, pageRedirectSlug, iframeTarget);
                } else {
                    webopacCatalogueSerach(iframeID, wrapperClassName, contentTabID, pageRedirectSlug, iframeTarget);
                }
            }
            iframeLoading = true;
            $('.wrapper-loading-v2').hide();
            $('.' + contentTabID).hide();
            $('.wrapper-loading').hide();
        });

    }

}

function searchFilterWP(iframeID, WEBOPAC_DOMAIN, PAGE_URL, ACTION, WRAPPER_ID) {

    $('iframe#' + iframeID).bind('load', function () {

        var iFrameDOMContent = $('iframe#' + iframeID).contents();
        var webOPAC__filter = iFrameDOMContent.find('#' + WRAPPER_ID);

        var webOPAC__filter_toolBar = iFrameDOMContent.find('#' + WRAPPER_ID + ' #FilterToolbar');
        if (typeof webOPAC__filter_toolBar !== 'undefined') {
            var webOPAC__filter_toolBarID = $(webOPAC__filter_toolBar).attr('id');
        }
        if (typeof webOPAC__filter_toolBarID !== 'undefined') {
            $('#webOPAC__filter').show();
            $('#webOPAC__filter .webOPAC__filter_cnt').html($(webOPAC__filter).html());
            $('#filtercontent').show();
            $('#filterDropDown').height('auto');
            $('#webOPAC__filter .dropdown-menu li a').each(function () {
                var hrefUrl = $(this).attr('href');
                var dataQuery = hrefUrl.split("data=");
                $(this).attr('href', WEBOPAC_DOMAIN + '/' + PAGE_URL + '/?data=' + dataQuery[1] + (ACTION ? '&action=' + ACTION : ''));
            });
            $('#webOPAC__filter .FilterPanelGroupWrapper a').each(function () {
                var hrefUrl = $(this).attr('href');
                var dataQuery = hrefUrl.split("data=");
                $(this).attr('href', WEBOPAC_DOMAIN + '/' + PAGE_URL + '/?data=' + dataQuery[1] + (ACTION ? '&action=' + ACTION : ''));
            });


            $('#webOPAC__filter .btn-toolbar.activeFilterConditions .btn-group a, #webOPAC__filter .activatedFilterContent .ActivatedFilterItem a').each(function () {
                var hrefUrl = $(this).attr('href');
                var dataQuery = hrefUrl.split("data=");
                $(this).attr('href', WEBOPAC_DOMAIN + '/' + PAGE_URL + '/?data=' + dataQuery[1] + (ACTION ? '&action=' + ACTION : ''));
            });

            //$('.FilterPanelGroupEntriesWrapper').mCustomScrollbar();

            if ($(window).width() < 480) {

                $('#webOPAC__filter').addClass('webOPAC_hide');
                setCookie("filter_status", 'close', 30);
                // $('#filterSliderWrapper').mCustomScrollbar({
                //     axis:"y",
                // });
            } else {
                // $('#filterSliderWrapper').mCustomScrollbar({
                //     axis:"x",
                // });
                // $('.webOPAC__filter_right #filterSliderWrapper').mCustomScrollbar({
                //     axis:"y",
                // });                

            }

            $('#expertFilter').click(function () {
                $(this).toggle();
                $('.webOPAC__filter_left #filterSliderWrapper, .webOPAC__filter_right #filterSliderWrapper').height($(window).height() - 200);
                // $('.webOPAC__filter_left #filterSliderWrapper, .webOPAC__filter_right #filterSliderWrapper').mCustomScrollbar({
                //     axis:"y",
                // });                
                $('#simpleFilter').toggle();
                $('#filterSlider').toggle();
                $('#filterDropDown').toggle();
            });

            $('#simpleFilter').click(function () {
                $(this).toggle();
                $('#expertFilter').toggle();
                $('#filterDropDown').toggle();
                $('#filterSlider').toggle();
            });
        }

    });
}

$(function () {
    // weboapc Catalogue - search
    var webopacLinks = $('.webopacCatalogueLink');
    $(webopacLinks).each(function () {
        $(this).on('click', function () {
            var iframeContent = $('#opaciframe-' + $(this).data('iframe'));
            var iFrameDOM = $('iframe.opaciframe').contents();
            var webopacForm = iFrameDOM.find('form');
            var actionFormUrl = $(webopacForm).attr('action');
            var targetIframeContent = true;

            if ((typeof webopacForm !== "undefined") && (actionFormUrl.indexOf("detail.aspx") > 0)) {
                targetIframeContent = false;
            }

            if ((typeof $(iframeContent).attr('id') == "undefined") || (!targetIframeContent)) {
                $('#targetIframe').attr('src', WEBOPAC_DOMAIN + '/webopac/' + $(this).data('iframe') + '.aspx?referer=MbaT87');
                $('#targetIframe').attr('data-location', 'wpiframe');
                webopacCatalogue('targetIframe', $(this).data('wrapper'), 'webopac-' + $(this).data('pageurl'), $(this).data('pageurl'), 'wpiframe');
            } else {
                $('#targetIframe').attr('data-location', '');
                webopacCatalogue('opaciframe-' + $(this).data('iframe'), $(this).data('wrapper'), 'webopac-' + $(this).data('pageurl'), $(this).data('pageurl'), '');
                $('.card-body .wrapper-loading-v2').hide();
            }

        });
    })
});    
    //#weboapc Catalogue - search  
