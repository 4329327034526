$( document ).ready(function() {
    var WEBOPAC_DOMAIN = window.location.origin; 



    $(window).click(function() {
        $('.dt-popover').hide();
    });
     
    // hide #loginBox 
    $('#loginBox').hide();
    $( '#popUpButtonLogon' ).hide();
    $('<input id="popUpButtonLogon_wp" class="btn btn-primary" type="button" value="Anmelden" style="width:10em">').insertAfter('#popUpButtonLogon');

    // popup login ( location: result list page )
    $( '#popUpButtonLogon_wp' ).on( "click", function() {
        var targetForm  = $('#loginBox');    
        var username    = $(targetForm).find( $("[name*=username]") );
        var password    = $(targetForm).find( $("[name*=password]") );
        var data_wp     = $(targetForm).find( $("#data_wp") );
        $.ajax({
            type: "POST",
            url: WEBOPAC_DOMAIN + '/webopac/service/UserService.ashx',
            data: ({
                action:     'userlogin',
                user:       $(username).val(),
                password:   $(password).val()
            }),
            contentType: "application/x-www-form-urlencoded; charset=UTF-8",
            success: function (r) {
                if ( r == 'ok' ) {
                    Cookies.set('logged', '1', { expires: 1, path: '/' });
                    window.top.location.href = '/overview/?action=reservate&data=' + $(data_wp).val();
                } else {
                    $('#errorMessage').html(r).show();
                }
            },
            error: function (r) {
                alert(r.responseText);
            },
            failure: function (r) {
                alert(r.responseText);
            }
        });        
      });

     
// $('#slide-details iframe#opaciframe_details').bind("load", function() {
//         linksDetails = $('#detail-center a, #MediaItemLocationWrapper a, .MediaItemLocation.popover a, .notation a');
        
//         var iFrameWrapperHeight = $( '#slide-details').height();
//         var iframe = document.getElementById('opaciframe_details');
//         var iframeHeight = iframe.contentWindow.document.body.scrollHeight + 100;
//         $('#loginBox').addClass('topLogin');
//         $('#slide-details .slide-content').height( iFrameWrapperHeight );  
//         $('#slide-details .slide-content iframe').height( iframeHeight );  
        
//         // 'Vorbestellen' - details page
//         $('.tableCopies .btn-reservation.loginButton').click(function( e ) {
//             e.preventDefault();
//             e.stopPropagation();
//             $( '#black-overlay-v2' ).attr( 'style', 'z-index:9998;');
//             $('#btnLogin')[0].click();
//             var hrefUrl = $(this).attr('href');
//             var dataQuery = hrefUrl.split("data=");
//             var wp_buttonLogon = $('.wp_buttonLogon');
//             $('<input id="data_wp" type="hidden" value="' + dataQuery[1] + '">').insertAfter( wp_buttonLogon );
//         });
//         $('.tableCopies .btn-reservation').not( $('.loginButton') ).click(function( e ) {
//             e.preventDefault();
//             e.stopPropagation();
//             var hrefUrl = $(this).attr('href');
//             var dataQuery = hrefUrl.split("data=");
//             window.location.href = '/overview/?action=reservate&data=' + dataQuery[1];
//         });    

//         $(linksDetails).each(function() {
//             dataHref = $(this).attr('href');
//             var dataDetails = dataHref.split("data=");
//             if (typeof dataDetails[1] !== "undefined") {
//                 $(this).click(function(event){
//                     event.preventDefault();
//                     window.location.href = '/suchen/?data=' + dataDetails[1];
//                 });
//             }
//         });

//      });

     slideDetailsPage();
});

// rewrite details links // close/open slide
function closeSlide( currentUrl ) {
    $( '#slide-details').removeClass('open');
    $('body').removeClass('scroll-off');
    $( '#black-overlay').removeClass('on');
    $( '#slide-details .slide-content .iframe-content').html('');    
    window.history.pushState({ path: currentUrl }, '', currentUrl);
}
function openSlide( dataUrlEncoded, currentUrl ) {
    console.log('openSlide wp');
    $( '#slide-details').addClass('open');
    $( '#black-overlay').addClass('on');
    $('body').addClass('scroll-off');
    $( '#slide-details .wrapper-loading').show();
    var urlDetails = window.location.origin + '/details?popup=true&data=' + dataUrlEncoded;
    
    $( '#slide-details .slide-content .iframe-content').load( urlDetails, function( response, status, xhr ) {
        $( '#slide-details .wrapper-loading').hide();
        var detailsUrl = window.location.protocol + "//" + window.location.host + '/?data=' + dataUrlEncoded + '&popup=true&share=true';
        if ( status == 'error' ) {
            var msg = 'Sorry but there was an error:';
            console.log( msg );
        }

    } );

    $( '#slide-details .slide-off, #black-overlay' ).click(function() {
        closeSlide( currentUrl );
    });

}

function slideDetailsPage() {
    console.log('slideDetailsPage wp');
        links = $('.widget-wrapper-book a');
        $(links).each(function() {
            var dataHref = $(this).attr('href');
            if (typeof dataHref !== 'undefined') {
                $(this).attr('href','javascript:void(0)'); 
                $(this).click(function() {

                    var dataDetails = dataHref.split('data=');
                    if (typeof dataDetails[1] !== 'undefined') {
                        dataIframe = dataDetails[1]; // data Url Encoded
                    }

                    var currentUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + location.search;
                    var detailsUrl = window.location.protocol + "//" + window.location.host + '/?data=' + dataIframe + '&popup=true&share=true';
                    window.history.pushState({ path: detailsUrl }, '', detailsUrl);
                    openSlide( dataIframe, currentUrl );
    
                });
            }
        });

        

}

var _rightMenu = $('.floatting-menu' );
$( '#black-overlay' ).on( 'click', function ( e ) { 
    if ( $( _rightMenu ).hasClass('open') ) {
        $( _rightMenu ).removeClass('open');
        $('html').toggleClass('scroll-off');
        $( '#black-overlay').removeClass('on');        
    }
});

