

// user menu
function getUserMenuFromIframe() {
    $('#targetIframe').attr('src', window.location.origin + '/webopac/user/overview.aspx?referer=MbaT87').addClass('opaciframe-overview-wp');
    if (!$('.floatting-menu .user-menu ul').length) {
        $('.floatting-menu .wrapper-loading').show();
    };
    $('.opaciframe-overview-wp').bind("load", function () {
        // SubMenu
        $('.floatting-menu .wrapper-loading').hide();
        var iFrameDOM = $(this).contents();
        var userMenu = iFrameDOM.find('#SubMenu ul');
        $(userMenu).find('a').each(function () {
            var hrefAttr = $(this).attr('href');
            var webopacUrl = hrefAttr.split('.');
            if (webopacUrl.length == 2) {
                if (typeof webopacUrl[0] !== "undefined") {
                    if (webopacUrl[0] == 'logout') {
                        $(this).attr('href', '/logout');
                    } else {
                        $(this).attr('href', '/overview/?action=' + webopacUrl[0]);
                    }
                }
            } else {
                $(this).attr('href', '/overview');
            }
        });
        if (!$('.floatting-menu .user-menu ul').length) {
            $('.floatting-menu .user-menu h3').after($(userMenu));
        }
    });
}

$(function () {

    $('#opaciframe').iFrameResize([{ warningTimeout: 10000 }]);
    var iFrames = $('main .opaciframe');

    $(iFrames).on('load', function () {

        this.style.height = parseInt(this.contentWindow.document.body.offsetHeight + 20) + 'px';

        var iFrameDOM = $("mainframe.opaciframe").contents();
        var filtercontent = iFrameDOM.find('#filtercontent');
        var filterSettings = iFrameDOM.find('#filterSettings');
        var bullet_toggle = iFrameDOM.find('#bullet_toggle');

        $(filtercontent).attr('style', 'display:none;');
        $(filterSettings).attr('style', 'display:none;');
        $(bullet_toggle).attr('class', 'fa toggleFilter fa-plus-square-o');

        $(iFrameDOM).on('click', function () {
            $('#formLoginTop').hide();
        });

        var webopacForm = iFrameDOM.find('form');
        var actionFormUrl = $(webopacForm).attr('action');

    });

    // logout redirect 
    $('iframe#opaciframe-logout').bind("load", function () {
        window.top.location.href = '/';
    });


    $('#searchwp_v2').on('keypress', function (e) {
        if (e.which == 13) { // Enter key pressed
            $('.box-search-loading').show();
        }
    });
    $('#searchwpOK_v2').on('click', function () {
        $('.box-search-loading').show();
        $('#webopac_search').submit();
    });
    $('#searchBTN').on('click', function () {
        var iFrameDOM = $("iframe#targetIframe").contents();
        iFrameDOM.find('#ctl00_ContentPlaceHolderMain_ButtonStartSearch').click();
    });
    //#green box search

       

    // login box 
    $('[class*=wp_buttonLogon]').on('click', function () {
        var targetForm = $('#' + $(this).data('target'));
        var username = $(targetForm).find($('[name*=wp_username]'));
        var password = $(targetForm).find($('[name*=wp_password]'));
        var btnSubmit = $(this);
        $('#formLoginTop .wrapper-loading-v2').show();
        $.ajax({
            type: 'POST',
            url: WEBOPAC_DOMAIN + '/webopac/service/UserService.ashx',
            data: ({
                action: 'userlogin',
                user: $(username).val(),
                password: $(password).val()
            }),
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            success: function (r) {
                if (r == 'ok') {
                    // 20 minutes - Default idle timeout set in the webopac app
                    setCookieMinutes('logged', '1', 20);
                    var data_wp = $('#formLoginTop').find($('#data_wp')); // popup wp login
                    if (typeof data_wp !== "undefined" && $(data_wp).val()) {
                        window.location.href = '/overview/?action=reservate&data=' + $(data_wp).val();
                    } else {
                        window.location.href = '/overview/';
                    }
                } else {
                    $('.dt-login-msg').html(r).show();
                    $('#formLoginTop .wrapper-loading-v2').hide();
                }
            },
            error: function (r) {
                alert(r.responseText);
            },
            failure: function (r) {
                alert(r.responseText);
            }
        });
        return false;
    });

    $('#wp_password').on('keyup', function (event) {
        if (event.keyCode === 13) {
            $('[class*=wp_buttonLogon]').click();
        }
    })

    var iFrameHero = $('iframe#viewer').contents();
    $(iFrameHero).on('click', function () {
        $('#formLoginTop').hide();
        $('#black-overlay-v2').removeClass('on');
        $('body').removeClass('scroll-off');
    });
    $(window).on('click', function () {
        $('#formLoginTop').hide();
        $('#black-overlay-v2').removeClass('on');
        $('body').removeClass('scroll-off');
    });
    $('#formLoginTop .close').on('click', function () {
        $('#formLoginTop').hide();
        $('#black-overlay-v2').removeClass('on');
        $('body').removeClass('scroll-off');
    });
    $('#formLoginTop').on('click', function (event) {
        event.stopPropagation();
    })
    $('#formLoginTop .eye').on('click', function () {
        $(this).toggleClass('on');
        $(this).find('i').toggleClass('fa-eye-slash');
        if ($(this).hasClass('on')) {
            $('#formLoginTop #wp_password').attr('type', 'text');
        } else {
            $('#formLoginTop #wp_password').attr('type', 'password');
        }
    });

    $('.widget-wrapper-book h4', '.layout-books h3').ellipsis({
        lines: 2,
        ellipClass: 'ellip',
        responsive: true
    });

    $('.widget-wrapper-book h5').ellipsis({
        lines: 1,
        ellipClass: 'ellip',
        responsive: true
    });

    $('.webopac-mobile-popup button').on('click', function () {
        $('.webopac-mobile-popup').hide();
    });

    // result list - bottom filter
    $('.webOPAC__filter_label').on('click', function () {

        var filter_status = getCookie('filter_status');
        if (filter_status != '') {
            if (filter_status == 'close') {
                $('#webOPAC__filter').removeClass('webOPAC_hide');
                setCookie("filter_status", '', 30);
            }
        } else {
            setCookie("filter_status", 'close', 30);
            $('#webOPAC__filter').addClass('webOPAC_hide');
        }

        if ($('#webOPAC__filter').hasClass('webOPAC__filter_left')) {
            $('body').toggleClass('filter_left');
        }
        if ($('#webOPAC__filter').hasClass('webOPAC__filter_right')) {
            $('body').toggleClass('filter_right');
        }

    });
    if (getCookie('filter_status') == 'close') {
        $('#webOPAC__filter').addClass('webOPAC_hide');
    } else {
        if ($('#webOPAC__filter').hasClass('webOPAC__filter_left')) {
            $('body').addClass('filter_left');
        }
        if ($('#webOPAC__filter').hasClass('webOPAC__filter_right')) {
            $('body').addClass('filter_right');
        }
    }

    function webopacSearchAutocomplete(textBoxSearchID, submitButtonID) {
        var autocompleteProviderUrl = WEBOPAC_DOMAIN + "/webopac/service/SearchAutoCompleteProvider.ashx";
        var $textBoxSearch = $('#' + textBoxSearchID);
        var defaultOptions = {
            serviceUrl: autocompleteProviderUrl,
            minChars: 1,
            zIndex: 2000,
            triggerSelectOnValidInput: false,
            preventBadQueries: false,
            deferRequestBy: 300,
            params: {
                searchfield: 2,
            },
            source: function (request, response) {
                $.get(autocompleteProviderUrl, { query: request.term, serachfield: 2 }, function (data) {
                    response(data.suggestions);
                });
            },
            select: function (suggestion, ui) {
                $($textBoxSearch).val(ui.item.label);
                $('#' + submitButtonID).click();
            }
        }

        $textBoxSearch.autocomplete(defaultOptions);
    }
    webopacSearchAutocomplete('searchwp_v2', 'searchwpOK_v2');
    webopacSearchAutocomplete('searchwp_tab', 'searchwp_submit_tab');

    $('.hero-slide').slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 2000,
        fade: true,
        cssEase: 'linear',
        arrows: true,
        adaptiveHeight: true,
    });

    function heroSlide() {
        var slideHeight = $('.hero .slick-slider').height();
        if (typeof $(slideHeight) != 'undefined') {
            if (($(window).height() > 700) && ($(window).width() > 768)) {
                //slideHeight = slideHeight - 200;
            }
            $('body.home').attr('style', 'padding-top: ' + slideHeight + 'px;');
        }
    }
    function heroSlider() {
        imagesSlider = $('#carousel .carousel-item img');
        $(imagesSlider).each(function() {
            if ($(window).width() < 480) {
                $(this).attr('src', $(this).data('mobile') );
            } else {
                $(this).attr('src', $(this).data('desktop') );
            }    
        });
        $('#carousel .carousel-item img').show();
    }
    heroSlider();

    if ($(window).width() < 768) {
        $('#navbarToggler').removeClass('show');
    }

    $(window).on('resize', function () {
        heroSlider();
        //heroSlide();

        if ($(window).width() < 480) {
            $('#webOPAC__filter').addClass('webOPAC_hide');
            setCookie("filter_status", 'close', 30);
            if ($('#webOPAC__filter').hasClass('webOPAC__filter_left')) {
                $('body').removeClass('filter_left');
            } else {
                $('body').removeClass('filter_right');
            }
        }
    })

    $('iframe#webopac-login').bind("load", function () {
        var iFrameDOM = $('iframe#webopac-login').contents();
        var loginForm = iFrameDOM.find('#LoginContent');
        if (!$(loginForm).length) {
            setCookieMinutes('logged', '1', 20);
        } else {
            Cookies.remove('logged');
        }
    });

    // webopac-info-box
    $('.webopac-info-box .close').on('click', function () {
        $('.webopac-info-box').hide();
        setCookie("webopac_info_box", '1', 1);
    });





    // contact right
    $('.contact-phone-wrap').hover(
        function () {
            $(this).addClass('active');
        },
        function () {
            $(this).removeClass('active');
        });

    $('.contact-phone-wrap > a').click(function (e) {
        e.preventDefault();
        $(this).parent().addClass('active');
    });

    $('.contact-phone-wrap .close').click(function (e) {
        // e.preventDefault();
        $(this).parent().parent().removeClass('active');
    });
    //#contact right

});

function setCookieMinutes(cname, cvalue, minutes) {
    var d = new Date();
    d.setTime(d.getTime() + (minutes * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

